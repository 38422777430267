import React from "react"
import styled from "styled-components"
import Layout from "../components/layout/Layout.js"
import { Row } from "../components/row/Row.js"
import {
  Title,
  Heading,
  SubHeading,
} from "../components/title/Title.js"
import Seo from "../components/seo"
import { TextMedium } from "../components/text/Text.js"
import { Card33, Card33White } from "../components/card/Card.js"
import { sizes } from "../styles/sizes"
import { colors } from "../styles/colors"
import { Section } from "../components/section/Section.js"
import {
  PrimaryButton,
  Contact,
} from "../components/button/Button.js"
import featuredImage from '../static/blogImages/undraw_shopping_eii3.png'
import { Testimonials } from "../components/testimonial/Testimonial.js"


const StyledTable = styled.table`
  margin: ${sizes.sizeL} auto;
  text-align: center;
  border-radius: 1rem;

  thead,
  tfoot,
  tbody {
    width: 100%;
  }

  th {
    padding: ${sizes.sizeXS};
    background-color: ${colors.colorTextMedium};
    color: ${colors.colorWhite};
  }

  td {
    padding: ${sizes.sizeXS};

    &:nth-child(1) {
      background-color: ${colors.colorContentLightest};
    }

    &:nth-child(2) {
      background-color: ${colors.colorContentLight};
    }

    &:nth-child(odd) {
      background-color: ${colors.colorContentLightest};
    }
  }
`

function Prices({ children, ...rest }) {
  return (
    <Layout>
      <Seo title="Prices" 
      description="See our price for content strategy development, content audit, campaigns or 
      monthly content marketing packages."
      image={featuredImage}
      featuredImage={featuredImage} />

      <Section isCentered>
        <Title isPrimary isCentered title="Packages and prices" />
        <Row isCentered>
          <Card33White>
            <Heading heading="Content strategy" />
            <TextMedium textMedium="Turnaround time: 6 weeks" />
            <StyledTable>
              <thead>
                <th>Deliverables</th>
              </thead>
              <tbody>
                <tr>
                  <td>Audit & inventory</td>
                </tr>
                <tr>
                  <td>Competitor & channel research</td>
                </tr>
                <tr>
                  <td>Audience research & ICP development</td>
                </tr>
                <tr>
                  <td>Theme & keyword research</td>
                </tr>
                <tr>
                  <td>Content gap analysis</td>
                </tr>
                <tr>
                  <td>Content types</td>
                </tr>
                <tr>
                  <td>Content calendar (6 months)</td>
                </tr>
                <tr>
                  <td>Content distribution sequences</td>
                </tr>
              </tbody>
            </StyledTable>
            <SubHeading subheading="Total price: 9.600 &euro;" />
            <PrimaryButton
              id="CSsmall-pricingPage"
              isCentered
              buttonText="Order now"
              buttonLink="/contact/"
            />
          </Card33White>
          <Card33>
            <Heading isCentered heading="Content inventory and audit" />
            <TextMedium textMedium="Turnaround time: 4 weeks" />
            <StyledTable>
              <thead>
                <th>Deliverables</th>
              </thead>
              <tbody>
                <tr>
                  <td>Content inventory</td>
                </tr>
                <tr>
                  <td>Content audit - qualitative analysis</td>
                </tr>
                <tr>
                  <td>Content/funnel mapping</td>
                </tr>
                <tr>
                  <td>Content performance analysis</td>
                </tr>
                <tr>
                  <td>Content performance report</td>
                </tr>
                <tr>
                  <td>Audience & competitor research</td>
                </tr>
                <tr>
                  <td>Content gap analysis</td>
                </tr>
                <tr>
                  <td>Content strategy recommendations</td>
                </tr>
              </tbody>
            </StyledTable>
            <SubHeading subheading="Starting price: 6.800 &euro;" />
            <Contact
              id="contAudit-pricingPage"
              isCentered
              buttonText="Order now"
              buttonLink="/contact/"
            />
          </Card33>
          {/* <Card33White>
            <Heading isCentered heading="Content marketing package" />
            <TextMedium textMedium="Turnaround time: Quarterly" />
            <StyledTable>
              <thead>
                <th>Deliverables</th>
              </thead>
              <tbody>
                <tr>
                  <td>Target audience research*</td>
                </tr>
                <tr>
                  <td>Theme & keyword research*</td>
                </tr>
                <tr>
                  <td>Competitor & channel research*</td>
                </tr>
                <tr>
                  <td>Quarterly calendar</td>
                </tr>
                <tr>
                  <td>Writing: 4 articles (~1800 words)/month</td>
                </tr>
                <tr>
                  <td>Writing: White paper (~5000 words)</td>
                </tr>
                <tr>
                  <td>Social media graphics (articles & white paper)</td>
                </tr>
                <tr>
                  <td>Email series (articles & white paper)</td>
                </tr>
              </tbody>
            </StyledTable>
            <SubHeading subheading="Starting price: 5.800 &euro;/month" />
            <PrimaryButton
              id="contentPackage-pricingPage"
              isCentered
              buttonText="Order now"
              buttonLink="/contact/"
            />
          </Card33White> */}
        </Row>
      </Section>

      <Testimonials/>
    </Layout>
  )
}

export default Prices
