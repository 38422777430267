import React from "react"
import {
  StyledCard,
  StyledCard33,
  StyledCard66,
  StyledCard50,
  StyledCard50White,
  StyledCard50Dark,
  StyledCard33White,
} from "./style.js"

export const Card = ({ children }) => <StyledCard>{children}</StyledCard>

export const Card66 = ({ children }) => <StyledCard66>{children}</StyledCard66>

export const Card33 = ({ children }) => <StyledCard33>{children}</StyledCard33>

export const Card50 = ({ children }) => <StyledCard50>{children}</StyledCard50>

export const Card50White = ({ children }) => (
  <StyledCard50White>{children}</StyledCard50White>
)

export const Card33White = ({ children }) => (
  <StyledCard33White>{children}</StyledCard33White>
)

export const Card50Dark = ({ children }) => (
  <StyledCard50Dark>{children}</StyledCard50Dark>
)
