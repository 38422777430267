import styled from "styled-components"
import { colors } from "../../styles/colors.js"
import { widths, sizes, device } from "../../styles/sizes"

export const StyledCard = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  flex-basis: 1;
  padding: ${sizes.sizeM} ${sizes.sizeM} ${sizes.sizeXXL};
  margin: ${sizes.sizeXL} auto ${sizes.sizeM} auto;
  border-radius: 1rem;
`

export const StyledCard50 = styled(StyledCard)`
  width: ${widths.width100};

  @media ${device.mobileL} {
    width: ${widths.width100};
  }

  @media ${device.tablet} {
    width: ${widths.width100};
  }

  @media ${device.laptop} {
    width: ${widths.width50};
  }

  @media ${device.desktop} {
    width: ${widths.width50};
  }

  background-color: ${colors.colorContentLightest};
`

export const StyledCard50Dark = styled(StyledCard50)`
  background-color: ${colors.colorTextMedium};
`

export const StyledCard50White = styled(StyledCard50)`
  background-color: ${colors.colorWhite};
  border: solid 1px ${colors.colorTextMedium};
`

export const StyledCard33 = styled(StyledCard)`
  margin: ${sizes.sizeM} ${sizes.sizeS};
  width: ${widths.width100};
  flex-basis: 1;
  background-color: ${colors.colorContentLightest};

  @media ${device.mobileL} {
    width: ${widths.width100};
  }

  @media ${device.tablet} {
    width: ${widths.width100};
  }

  @media ${device.laptop} {
    width: ${widths.width33};
  }

  @media ${device.desktop} {
    width: ${widths.width33};
  }
`

export const StyledCard33White = styled(StyledCard33)`
  background-color: ${colors.colorWhite};
  border: solid 1px ${colors.colorTextMedium};
`

export const StyledCard66 = styled(StyledCard)`
  margin: ${sizes.sizeM} ${sizes.sizeXS};
  width: ${widths.width100};
  flex-basis: 1;
  background-color: ${colors.colorContentLighter};

  @media ${device.mobileL} {
    width: ${widths.width100};
  }

  @media ${device.tablet} {
    width: ${widths.width100};
  }

  @media ${device.laptop} {
    width: ${widths.width66};
  }

  @media ${device.desktop} {
    width: ${widths.width66};
  }
`
